<template>
  <el-button id="create-close-lead-btn" :disabled="isDisabled" @click="$emit('click')">
    {{ isCreate ? 'Create Lead' : 'Close Lead' }}
    <img src="../../assets/icon/white-arrow.svg" alt="" />
  </el-button>
</template>

<script>
export default {
  props: {
    isCreate: {
      type: Boolean,
      default:false
    },
    isDisabled: {
      type: Boolean,
      default:false
    }
  }
}
</script>

<style lang="scss" scoped>
#create-close-lead-btn {
  padding: 16px 40px;
  width: 185px;
  height: 55px;
  background: #1c4a5e;
  border-radius: 4px;

  font-family: 'Manrope';
  font-style: normal;
  font-weight: 700;
  font-size: 15px;
  line-height: 20px;
  color: #ffffff;

  img{
    margin-left:10px;
  }
}
</style>
